import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
//
import { PrimeIcons } from "primereact/api";
import { Route, Routes } from "react-router-dom";
import TopToolBar from "./components/top-toolbar";
import SideInformation from "./components/side-information";
//
import Home from "./views/home.js";
import AboutUs from "./views/about-us.js";
import ContactUs from "./views/contact-us.js";
import Help from "./views/help.js";
import Login from "./views/login.js";
import Profile from "./views/profile.js";
//
import AdditionalServiceRequest from "./views/additional-service-request.js";
import InitialInstallation from "./views/initial-installation.js";
import OnlineHelp from "./views/online-help.js";
import { getToken, setToken } from "./utility";
import Troubleshooting from "./views/troubleshooting.js";
import AdditionalServiceList from "./views/additional-service-list.js";
import { useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useTranslation } from "react-i18next";

function App() {
	const { t } = useTranslation();
	const localizer = t;
	const token = getToken();
	const [hasMenu, setHasMenu] = useState(
		window.screen.width > 600 ? true : false
	);

	const confirmSignOut = function () {
		confirmDialog({
			message: localizer("Are you sure you want to signout?"),
			header: localizer("Confirm signout"),
			acceptLabel: localizer("Yes"),
			rejectLabel: localizer("No"),
			icon: "pi pi-exclamation-triangle",
			defaultFocus: "accept",
			accept,
			reject,
		});
	};

	const accept = function () {
		sessionStorage.clear();
		window.location = "/";
	};

	const reject = function () {};

	if (!token) {
		return (
			<Routes>
				<Route path="/" Component={Login} />
				<Route path="/home" Component={Home} />
			</Routes>
		);
	}

	return (
		<div className="main">
			<ConfirmDialog />
			<header className="head">
				<img src="./images/logo.svg" />
				<a
					className={PrimeIcons.SIGN_OUT}
					onClick={confirmSignOut}
					title={localizer("Signout")}
				></a>
				<a
					className={hasMenu ? PrimeIcons.TIMES : PrimeIcons.BARS}
					onClick={() => setHasMenu(!hasMenu)}
				></a>
			</header>
			<div className={hasMenu ? "content has-menu" : "content"}>
				<aside>
					<SideInformation />
				</aside>
				<div>
					<TopToolBar />
					<Routes>
						<Route path="/" Component={Home} />
						<Route path="/home" Component={Home} />
						<Route
							path="/initial-installation"
							Component={InitialInstallation}
						/>
						<Route
							path="/additional-service-request"
							Component={AdditionalServiceRequest}
						/>
						<Route
							path="/additional-service-list"
							Component={AdditionalServiceList}
						/>
						<Route path="/online-help" Component={OnlineHelp} />
						<Route path="/troubleshooting" Component={Troubleshooting} />
						<Route path="/profile" Component={Profile} />
						<Route path="/about-us" Component={AboutUs} />
						<Route path="/contact-us" Component={ContactUs} />
						<Route path="/help" Component={Help} />
					</Routes>
				</div>
			</div>
			<footer></footer>
		</div>
	);
}

export default App;
