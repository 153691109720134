import { FloatLabel } from "primereact/floatlabel";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller";
import UserController from "../services/user-controller";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { InputMask } from "primereact/inputmask";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

export default function AdditionalServiceRequest() {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const userController = new UserController();
	const toast = useRef(null);
	//
	const [product, setProduct] = useState({});
	//
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [customerPackageId, setCustomerPackageId] = useState("");
	//
	const [requestDate, setRequestDate] = useState("");
	const [office, setOffice] = useState("");
	const [serial, setSerial] = useState("");
	const [quantity, setQuantity] = useState(1);
	const [customerName, setCustomerName] = useState("");
	const [mobile, setMobile] = useState("");
	const [tel, setTel] = useState("");
	const [email, setEmail] = useState("");
	const [requestDescription, setRequestDescription] = useState("");
	const [reagentName, setReagentName] = useState("");
	const [fileName, setFileName] = useState("");
	const [fileMimeType, setFileMimeType] = useState("");
	const [fileData, setFileData] = useState("");
	const [aggrement, setAggrement] = useState(false);
	const [bankAccountNumber, setBankAccountNumber] = useState("");
	//
	const offices = [
		{ name: "دفتر مرکزی", id: 1 },
		{ name: "دفتر تهران", id: 2 },
		{ name: "دفتر مشهد", id: 3 },
	];
	//>
	const createFileData = async (event) => {
		// convert file to base64 encoded
		const file = event.files[0];
		setFileName(file.name);
		setFileMimeType(file.type);
		console.info(file);

		const reader = new FileReader();
		let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

		reader.readAsDataURL(blob);

		reader.onloadend = function () {
			setFileData(reader.result);
			toast.current.show({
				severity: "info",
				summary: localizer("انتخاب فایل"),
				detail: localizer("فایل با موفقیت برای آپلود انتخاب شد"),
			});
		};
	};
	//>
	const getData = async function () {
		setIsLoading(true);
		// get profile
		const profileResult = await userController.getProfile();
		if (profileResult.isSuccess === false) console.error(profileResult.message);
		else {
			setCustomerName(profileResult.value.fullName);
			setMobile(profileResult.value.mobile);
			setTel(profileResult.value.tel);
		}
		//
		let productId = "";
		if (window.location.href.indexOf("?") > 0) {
			let urlParams = new URLSearchParams(window.location.href.split("?")[1]);
			productId = urlParams.get("id");
		}

		const productResult = await serviceController.getProduct(productId);
		if (productResult.isSuccess === false) console.error(productResult.message);
		else setProduct(productResult.value);
		//
		const packageResult = await serviceController.getPackage();
		if (packageResult.isSuccess === false) console.error(packageResult.message);
		else if (packageResult.value) {
			setSerial(packageResult.value.serial);
			setCustomerPackageId(packageResult.value.customerPackageId);
		}

		setIsLoading(false);
	};
	//
	const register = async function () {
		try {
			setIsSaving(true);
			const createResult = await serviceController.createAdditionalService(
				customerPackageId,
				product.productId,
				requestDate,
				office,
				quantity,
				customerName,
				tel,
				mobile,
				email,
				reagentName,
				requestDescription,
				bankAccountNumber,
				fileName,
				fileMimeType,
				fileData
			);
			if (createResult.isSuccess === false) throw createResult.message;

			setRequestDescription("");
			toast.current.show({
				severity: "success",
				summary: localizer("Register additional service"),
				detail: localizer("Your request successfully registered"),
			});
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};

	useEffect(() => {
		getData();
	}, []);

	//
	return (
		<article className="additional-service-request w-10 m-auto">
			<header>
				<h1>{product.name}</h1>
			</header>
			<img src={product.bannerUrl} />
			<h2>ثبت درخواست استفاده از {product.name}</h2>
			<div className="grid">
				<FloatLabel className="col-3">
					<DatePicker
						value={requestDate}
						onChange={(value) => setRequestDate(value)}
						calendar={persian}
						locale={persian_fa}
						id="requestDate"
						className="w-full"
						mapDays={({ date }) => {
							if (date.weekDay.index === 6)
								return {
									disabled: true,
									style: { color: "#f00" },
									//onClick: () => alert("شما نمیوانید روز تعطیل را انتخاب کنید")
								};
						}}
					/>
					<label htmlFor="requestDate">تاریخ مورد درخواست</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<Dropdown
						id="office"
						className="w-full"
						value={office}
						onChange={(e) => setOffice(e.value)}
						options={offices}
						optionLabel="name"
						optionValue="id"
					/>
					<label htmlFor="office">انتخاب دفتر خدمات</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<InputText
						id="serial"
						className="w-full ltr"
						value={serial}
						readOnly
					/>
					<label htmlFor="serial">شماره بسته</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<div className="p-inputgroup flex-1">
						<Button
							icon="pi pi-plus"
							className="p-button-success"
							onClick={() => setQuantity(Number(quantity) + 1)}
							disabled={quantity > 20}
						/>
						<InputText
							id="quantity"
							keyfilter="int"
							className="w-full ltr"
							value={quantity}
							onChange={(e) => setQuantity(e.target.value)}
						/>
						<Button
							icon="pi pi-minus"
							className="p-button-danger"
							onClick={() => setQuantity(Number(quantity) - 1)}
							disabled={quantity <= 1}
						/>
					</div>
					<label htmlFor="quantity">تعداد/مقدار مورد نیاز</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<InputText
						id="customerName"
						className="w-full"
						value={customerName}
						onChange={(e) => setCustomerName(e.target.value)}
					/>
					<label htmlFor="customerName">نام خدمات گیرنده</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<InputMask
						id="tel"
						className="w-full ltr"
						mask="999-99999999"
						slotChar="#"
						value={tel}
						onChange={(e) => setTel(e.target.value)}
					/>
					<label htmlFor="tel">تلفن</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<InputText
						id="mobile"
						keyfilter="int"
						className="w-full ltr"
						value={mobile}
						onChange={(e) => setMobile(e.target.value)}
					/>
					<label htmlFor="mobile">تلفن همراه</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<InputText
						id="email"
						keyfilter="email"
						className="w-full ltr"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label htmlFor="email">پست الکترونیک</label>
				</FloatLabel>

				<FloatLabel className="col-3">
					<InputText
						id="reagentName"
						className="w-full"
						value={reagentName}
						onChange={(e) => setReagentName(e.target.value)}
					/>
					<label htmlFor="reagentName">شخص معرف</label>
				</FloatLabel>

				<FloatLabel className="col-9">
					<InputText
						id="requestDescription"
						className="w-full"
						value={requestDescription}
						onChange={(e) => setRequestDescription(e.target.value)}
					/>
					<label htmlFor="requestDescription">توضیحات</label>
				</FloatLabel>

				<FloatLabel className="col-6">
					<InputMask
						id="bankAccountNumber"
						className="w-full ltr"
						mask="IR-9999-9999-9999-9999-9999-9999"
						slotChar="#"
						value={bankAccountNumber}
						onChange={(e) => setBankAccountNumber(e.target.value)}
					/>
					<label htmlFor="bankAccountNumber">شماره شبا</label>
				</FloatLabel>

				<div className="col-6">
					<p>
						در صورت نیاز به برگشت وجه مورد نیاز می باشد، حتما به نام مالک بسته
						باشد
					</p>
				</div>

				<div className="col-12 file-uploader">
					<FileUpload
						name="demo[]"
						chooseLabel="انتخاب فایل پیوست..."
						uploadLabel="تایید فایل"
						cancelLabel="انصراف"
						maxFileSize={1000000}
						customUpload
						uploadHandler={createFileData}
						mode="advanced"
						emptyTemplate={
							<p className="m-0">
								برای آپلود فایل پیوست خود را در این محل بکشید و رها کنید...
							</p>
						}
					/>
				</div>

				<h2>محاسبه قیمت این خدمات برای شما :</h2>
				<div className="col-6 amount">
					<labe>قیمت پایه این خدمات : </labe>
					<strong>{product.baseAmount?.toLocaleString()} ریال </strong>
				</div>
				<div className="col-6 amount">
					<labe>قیمت خدمات بر حسب تعداد شرکت شما : </labe>
					<strong>
						{(product.baseAmount * quantity)?.toLocaleString()} ریال
					</strong>
				</div>

				<h2>قوانین و مقررات مربوط به درخواست خدمات</h2>

				<p>{product.lawsAndConditions}</p>

				<div className="col-12">
					<input
						id="aggrement"
						type="checkbox"
						checked={aggrement}
						onChange={(e) => setAggrement(e.target.checked)}
					/>
					<label for="aggrement">
						قوانین و مقررات این خدمات را با دقت خوانده و آنها را می پذیرم
					</label>
				</div>

				<Toast ref={toast} />

				<div className="col-12 buttons">
					<Button
						label="ثبت درخواست"
						onClick={() => register()}
						severity="success"
						loading={isSaving}
						disabled={aggrement === false}
					/>
				</div>
			</div>
		</article>
	);
	//
}
