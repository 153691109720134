import axios from "axios";
import { API_BASE_URL, getToken } from "../utility";

export default function ServiceController() {
	const token = getToken();

	//> getPackage
	this.getPackage = async function () {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			let packageJson = sessionStorage.getItem("package");
			if (packageJson) result.value = JSON.parse(packageJson);
			if (!result.value) {
				const response = await axios.request({
					method: "get",
					maxBodyLength: Infinity,
					url: `${API_BASE_URL}Service/GetPackage`,
					headers: {
						accept: "application/json",
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					data: null,
				});
				result.value = response.data;
				sessionStorage.setItem("package", JSON.stringify(result.value));
			}
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};
	//> getRegions
	this.getRegions = async function (regionType, parentId) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetRegions?regionType=${regionType}&parentId=${parentId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getProducts
	this.getProducts = async function () {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetProducts`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getProduct
	this.getProduct = async function (productId) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetProduct?productId=${productId}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getOnlineHelps
	this.getOnlineHelps = async function (pageNumber, pagingSize) {
		let result = { isSuccess: true, message: "Success", value: null, total: 0 };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetOnlineHelps?pageNumber=${pageNumber}&pagingSize=${pagingSize}`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value;
			result.total = response.data.total;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> addOnlineHelpLog
	this.addOnlineHelpLog = async function (crmOnlineHelpId) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/AddOnlineHelpLog`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					crmOnlineHelpId: crmOnlineHelpId,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getOnlineHelpsSummary
	this.getOnlineHelpsSummary = async function () {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetOnlineHelpsSummary`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> createAdditionalService
	this.createAdditionalService = async function (
		customerPackageId,
		productId,
		requestDate,
		office,
		quantity,
		customerName,
		tel,
		mobile,
		email,
		reagentName,
		requestDescription,
		bankAccountNumber,
		fileName,
		fileMimeType,
		fileData
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/CreateAdditionalService`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					customerPackageId: customerPackageId,
					productId: productId,
					requestDate: requestDate ? new Date(requestDate) : null,
					office: office,
					quantity: quantity,
					customerName: customerName,
					tel: tel,
					mobile: mobile,
					email: email,
					reagentName: reagentName,
					requestDescription: requestDescription,
					bankAccountNumber: bankAccountNumber,
					fileName: fileName,
					fileMimeType: fileMimeType,
					fileData: fileData,
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> getTroubleshootings
	this.getTroubleshootings = async function ( ) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "get",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/GetTroubleshootings`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: null,
			});
			result.value = response.data.value; 
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> createTroubleshooting
	this.createTroubleshooting = async function (
		customerName,
		mobile,
		tel,
		requestDescription,
		requestDate
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/CreateTroubleshooting`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					customerName: customerName,
					mobile: mobile,
					tel: tel,
					requestDescription: requestDescription,
					requestDate: new Date(requestDate),
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//> createInstall
	this.createInstall = async function (
		cityId,
		customerName,
		mobile,
		tel,
		address,
		requestDescription,
		requestDate
	) {
		let result = { isSuccess: true, message: "Success", value: null };
		try {
			const response = await axios.request({
				method: "post",
				maxBodyLength: Infinity,
				url: `${API_BASE_URL}Service/CreateInstall`,
				headers: {
					accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				data: JSON.stringify({
					cityId: cityId,
					customerName: customerName,
					mobile: mobile,
					tel: tel,
					address: address,
					requestDescription: requestDescription,
					requestDate : new Date(requestDate)
				}),
			});
			result.value = response.data;
		} catch (error) {
			result.isSuccess = false;
			result.message = error.toString();
			if (error.response && error.response.status === 401)
				result.message = error.response.data;
		}
		return result;
	};

	//>
}
