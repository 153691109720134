import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller";
import { Toast } from "primereact/toast";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import UserController from "../services/user-controller";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

export default function InitialInstallation() {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const userController = new UserController();
	const toast = useRef(null);
	//
	const [isInitialInstallation, setIsInitialInstallation] = useState(false);
	const [startContractDate, setStartContractDate] = useState("");
	//> Commpon Properties
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	//> Properties
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const [crmStateId, setCrmStateId] = useState("");
	const [crmCityId, setCrmCityId] = useState(null);
	const [customerName, setCustomerName] = useState(null);
	const [mobile, setMobile] = useState(null);
	const [tel, setTel] = useState(null);
	const [address, setAddress] = useState(null);
	const [requestDescription, setRequestDescription] = useState(null);
  const [requestDate,setRequestDate] = useState("");

	useEffect(() => {
		getData();
	}, []);

	const getData = async function () {
		try {
			var packageResult = await serviceController.getPackage();
			if (packageResult.isSuccess === false) throw packageResult.message;

			setIsInitialInstallation(packageResult.value.isInitialInstallation);
			if (packageResult.value.isInitialInstallation === true)
				setStartContractDate(
					new Date(packageResult.value.startContractDate).toLocaleDateString(
						"fa-IR-u-nu-latn"
					)
				);
			else getDefaultValues();
		} catch (error) {
			alert(error.toString());
		}
	};

	const getDefaultValues = async function () {
		try {
			setIsLoading(true);
			// get profile
			const profileResult = await userController.getProfile();
			if (profileResult.isSuccess === false) alert(profileResult.message);
			else {
				setCustomerName(profileResult.value.fullName);
				setMobile(profileResult.value.mobile);
				setTel(profileResult.value.tel);
			}
			//
			const statesResult = await serviceController.getRegions(3, null);
			if (statesResult.isSuccess === false) alert(statesResult.message);
			else setStates(statesResult.value);
			//
			const packageResult = await serviceController.getPackage();
			if (packageResult.isSuccess === false) alert(packageResult.message);
			else if (packageResult.value) {
				await getCities(packageResult.value.stateId);
				setCrmCityId(packageResult.value.cityId);
			}
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	const getCities = async function (stateId) {
		setCrmStateId(stateId);
		setIsLoading(true);
		//
		const citiesResult = await serviceController.getRegions(null, stateId);
		if (citiesResult.isSuccess === false) console.error(citiesResult.message);
		else setCities(citiesResult.value);
		//
		setIsLoading(false);
	};

	const register = async function () {
		try {
			setIsSaving(true);
			const createResult = await serviceController.createInstall(
				crmCityId,
				customerName,
				mobile,
				tel,
				address,
				requestDescription,
        requestDate
			);
			if (createResult.isSuccess === false) throw createResult.message;

			setRequestDescription("");
			toast.current.show({
				severity: "success",
				summary: localizer("Register initial install"),
				detail: localizer("Your initial install registered"),
			});
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};

	return (
		<>
			{isInitialInstallation === true && (
				<div className="initial-installation success">
					<img src="./images/success-install.jpg" />
					<h2>نصب اولیه موفق</h2>
					<p>نصب اولیه در تاریخ {startContractDate} با موفقیت انجام شده است</p>
				</div>
			)}
			{isInitialInstallation === false && (
				<div className="initial-installation waiting">
					<img src="./images/waiting-install.jpg" />
					<h2>منتظر نصب اولیه</h2>
					<p>
						فعال سازی این بسته انجام نشده برای درخواست نصب لطفاً فرم زیر را
						تکمیل و ارسال نمایید
					</p>

					<div className="grid w-10 m-auto">
						<FloatLabel className="col-6">
							<Dropdown
								id="crmStateId"
								className="w-full"
								value={crmStateId}
								onChange={(e) => getCities(e.value)}
								options={states}
								optionLabel="name"
								optionValue="regionId"
								loading={isLoading}
								highlightOnSelect={false}
								filter
							/>
							<label htmlFor="crmStateId">{localizer("State")}</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<Dropdown
								id="crmCityId"
								className="w-full"
								value={crmCityId}
								onChange={(e) => setCrmCityId(e.value)}
								options={cities}
								optionLabel="name"
								optionValue="regionId"
								loading={isLoading}
								highlightOnSelect={false}
								filter
							/>
							<label htmlFor="crmCityId">{localizer("City")}</label>
						</FloatLabel>

						<FloatLabel className="col-12">
							<InputTextarea
								id="address"
								className="w-full"
								value={address}
								onChange={(e) => setAddress(e.target.value)}
							/>
							<label htmlFor="requestDescription">{localizer("Address")}</label>
						</FloatLabel>

						<FloatLabel className="col-6">
							<InputText
								id="customerName"
								className="w-full"
								value={customerName}
								onChange={(e) => setCustomerName(e.target.value)}
							/>
							<label htmlFor="customerName">
								{localizer("Request recipient name")}
							</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<InputText
								id="mobile"
								className="w-full ltr"
								value={mobile}
								onChange={(e) => setMobile(e.target.value)}
							/>
							<label htmlFor="mobile">
								{localizer("Request recipient mobile")}
							</label>
						</FloatLabel>
						<FloatLabel className="col-6">
							<InputText
								id="tel"
								className="w-full ltr"
								value={tel}
								onChange={(e) => setTel(e.target.value)}
							/>
							<label htmlFor="tel">{localizer("Customer service phone")}</label>
						</FloatLabel>

						<FloatLabel className="col-6">
							<DatePicker
								value={requestDate}
								onChange={(value) => setRequestDate(value)}
								calendar={persian}
								locale={persian_fa}
								id="requestDate"
								className="w-full"
								mapDays={({ date }) => {
									if (date.weekDay.index === 6)
										return {
											disabled: true,
											style: { color: "#f00" },
											//onClick: () => alert("شما نمیوانید روز تعطیل را انتخاب کنید")
										};
								}}
							/>
							<label htmlFor="requestDate">تاریخ هماهنگی نصب</label>
						</FloatLabel>

						<FloatLabel className="col-12">
							<InputTextarea
								id="requestDescription"
								className="w-full"
								value={requestDescription}
								onChange={(e) => setRequestDescription(e.target.value)}
							/>
							<label htmlFor="requestDescription">
								{localizer("Request description")}
							</label>
						</FloatLabel>
						<Toast ref={toast} />
						<Button
							label={localizer("Register request")}
							onClick={() => register()}
							severity="success"
							loading={isSaving}
						/>
					</div>
				</div>
			)}
		</>
	);
}
