import { useTranslation } from 'react-i18next';

export default function Ads() {
    const { t } = useTranslation();
    const localizer = t;

    return (
        <div className="ads">
            <h2>{localizer("Close fiscal year in Mahak accounting software")}</h2>
            <h3>{localizer("by 3 click")}</h3>
            <a href="#">{localizer("More information")}</a>
            <img src="/images/close-fiscal-year.png" />
        </div>
    )
}