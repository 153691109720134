import Ads from "../components/ads";
import ListServices from "../components/list-services";
import { getToken } from "../utility";

export default function Home() {
  const token = getToken();

  if (!token) return <h1>Access is denied!</h1>;
  else
    return (
      <>
        <Ads />
        <ListServices />
      </>
    );
}
