import { useTranslation } from "react-i18next";
import UserController from "../services/user-controller";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

export default function Profile() {
	const { t } = useTranslation();
	const localizer = t;
	const userController = new UserController();
	const toast = useRef(null);
	//
	const [profile, setProfile] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const [pageType, setPageType] = useState("request");
	const [verifyCode, setVerifyCode] = useState("");
	const [maskedMobile, setMaskedMobile] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	//
	useEffect(() => {
		getData();
	}, []);
	//> getData
	const getData = async function () {
		try {
			var profileResult = await userController.getProfile();
			if (profileResult.isSuccess === false) throw profileResult.message;

			setProfile(profileResult.value);
		} catch (error) {
			alert(error.toString());
		}
	};
	//> sendChangeVerifyCode
	const sendChangeVerifyCode = async function () {
		try {
			let errors = [];
			if (!newPassword) errors.push(localizer("Enter new password"));
			if (!confirmNewPassword)
				errors.push(localizer("Enter confrim new password"));
			if (
				newPassword &&
				confirmNewPassword &&
				newPassword !== confirmNewPassword
			)
				errors.push(
					localizer("Password and confrim new password must be equal")
				);
			if (errors.length > 0) {
				errors.map((item) => {
					toast.current.show({
						severity: "warn",
						summary: localizer("Change password"),
						detail: item,
					});
				});
				return;
			}
			//
			setIsSaving(true);
			var sendChangeVerifyCodeResult =
				await userController.sendChangeVerifyCode();
			if (sendChangeVerifyCodeResult.isSuccess === false)
				throw sendChangeVerifyCodeResult.message;

			setPageType("confirm");
			setMaskedMobile(sendChangeVerifyCodeResult.value);
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};

	//
	const changePassword = async function () {
		try {
			if (!verifyCode) return;

			setIsSaving(true);
			var changePasswordResult = await userController.changePassword(
				verifyCode,
				newPassword,
				confirmNewPassword
			);
			if (changePasswordResult.isSuccess === false)
				throw changePasswordResult.message;

			toast.current.show({
				severity: "success",
				summary: localizer("Change password"),
				detail: localizer("Password successfull changed"),
			});

			setPageType("success");
			setVerifyCode("");
			setNewPassword("");
			setConfirmNewPassword("");
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};
	//
	return (
		<article className="profile">
			<Toast ref={toast} />
			{pageType === "request" && (
				<div className="grid">
					<FloatLabel className="col-6">
						<InputText
							id="username"
							className="w-full"
							value={profile.username}
							readOnly
						/>
						<label htmlFor="username">{localizer("Package number")}</label>
					</FloatLabel>

					<FloatLabel className="col-6">
						<InputText
							id="fullName"
							className="w-full"
							value={profile.fullName}
							readOnly
						/>
						<label htmlFor="fullName">{localizer("Package owner")}</label>
					</FloatLabel>

					<FloatLabel className="col-6">
						<Password
							className="w-full"
							inputId="newPassword"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							toggleMask
							promptLabel={localizer("Choose a password")}
							weakLabel={localizer("Too simple")}
							mediumLabel={localizer("Average complexity")}
							strongLabel={localizer("Complex password")}
						/>
						<label htmlFor="newPassword">{localizer("New password")}</label>
					</FloatLabel>

					<FloatLabel className="col-6">
						<Password
							className="w-full"
							inputId="confirmNewPassword"
							value={confirmNewPassword}
							onChange={(e) => setConfirmNewPassword(e.target.value)}
							toggleMask
						/>
						<label htmlFor="confirmNewPassword">
							{localizer("Confirm new password")}
						</label>
					</FloatLabel>

					<Button
						label={localizer("Send verify code for change password")}
						onClick={() => sendChangeVerifyCode()}
						severity="success"
						loading={isSaving}
					/>
				</div>
			)}
			{pageType === "confirm" && (
				<div>
					<div>{localizer("Enter verify code sent to ")}</div>
					<p>
						<b className="site-ltr">{maskedMobile}</b>
					</p>
					<div className="mt-4 p-inputgroup flex-1 ltr w-full">
						<Button
							label={localizer("Change password")}
							onClick={() => changePassword()}
							severity="success"
							loading={isSaving}
						/>{" "}
						<InputText
							className="w-ful ltr"
							inputId="verifyCode"
							value={verifyCode}
							maxLength={6}
							onChange={(e) => setVerifyCode(e.target.value)}
						/>
					</div>
				</div>
			)}

			{pageType === "success" && (
				<div className="success">
					<svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
						<g fill="none" stroke="#8EC343" stroke-width="2">
							<circle
								cx="36"
								cy="36"
								r="35"
								style={{
									strokeDdasharray: "240px, 240px",
									strokeDashoffset: "480px",
								}}
							></circle>
							<path
								d="M17.417,37.778l9.93,9.909l25.444-25.393"
								style={{
									strokeDasharray: "50px, 50px",
									strokeDashoffset: "0px",
								}}
							></path>
						</g> 
					</svg>
					<h2>رمز عبور با موفقیت تغییر کرد</h2>
				</div>
			)}
		</article>
	);
}
