import { PrimeIcons } from "primereact/api";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller";

export default function AdditionalServiceList() {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const toast = useRef(null);
	//
	const [searchText,setSearchText] = useState("");
	const [products, setProducts] = useState([]);
	//
	const [isLoading, setIsLoading] = useState(false);
	//
	const getData = async function () {
		setIsLoading(true);
		//
		const productsResult = await serviceController.getProducts();
		if (productsResult.isSuccess === false)
			console.error(productsResult.message);
		else setProducts(productsResult.value);
		//
		const packageResult = await serviceController.getPackage();
		if (packageResult.isSuccess === false) console.error(packageResult.message);
		setIsLoading(false);
	};
	//
	const selectProduct = async function () {
		try {
			setIsLoading(true);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	//
	return (
		<article className="additional-service-list w-10 m-auto">
			<header>
				<h1>خدمات آزاد محک</h1>
			</header>
			<img src="/images/mazad.png" />
			<h2>لیست خدمات مازاد</h2>
			<div className="filter">
				<input
					type="text"
					autoComplete="off"
					value={searchText}
					placeholder="جستجو..."
					onChange={(e) => setSearchText(e.target.value)}
				/>
				<i className={PrimeIcons.SEARCH}></i>
			</div>
			<ul>
				{products.filter(item=> !searchText || item.name.indexOf(searchText) >= 0).map((item) => (
					<li>
						<img src={item.iconUrl} />
						<h2>{item.name}</h2>
						<p>{item.notes}</p>
						<a href={`/#/additional-service-request?id=${item.productId}`}>
							ثبت درخواست
						</a>
					</li>
				))}
			</ul>
		</article>
	);
	//
}
