import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";

export default function ListServices() {
	const serviceController = new ServiceController();
	const { t } = useTranslation();
	const localizer = t;
	const [items, setItems] = useState([]);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	const checkForInstallation = function (status, linkUrl) {
		if (status === "deactive") {
			setVisible(true);
			return false;
		}
		window.location = linkUrl;
		return true;
	};

	const getData = async function () {
		try {
			var packageResult = await serviceController.getPackage();
			if (packageResult.isSuccess === false) throw packageResult.message;

			let currentStatus =
				packageResult.value.isInitialInstallation === true ? "" : "deactive";
			setItems([
				{
					status:
						packageResult.value.isInitialInstallation === true ? "active" : "",
					title: "Install and config",
					name: "install-config",
					imageUrl: "/images/install-config.svg",
					linkUrl: "/#/initial-installation",
				},
				{
					status: currentStatus,
					title: "Software working education",
					name: "online-help",
					imageUrl: "/images/online-help.svg",
					linkUrl: "/#/online-help",
				},
				{
					status: currentStatus,
					title: "Bug fix",
					name: "bug-fix",
					imageUrl: "/images/bug-fix.svg",
					linkUrl: "/#/troubleshooting",
				},
				{
					status: currentStatus,
					title: "Additional services",
					name: "additional-services",
					imageUrl: "/images/additional-services.svg",
					linkUrl: "/#/additional-service-list",
				},
				// {
				// 	status: currentStatus,
				// 	title: "Survey",
				// 	name: "survey",
				// 	imageUrl: "/images/survey.svg",
				// },
				// {
				// 	status: currentStatus,
				// 	title: "Finance flow",
				// 	name: "finance-flow",
				// 	imageUrl: "/images/finance-flow.svg",
				// },
				// {
				// 	status: currentStatus,
				// 	title: "Coming soon",
				// 	name: "coming-soon",
				// 	imageUrl: "/images/coming-soon.svg",
				// },
				// {
				// 	status: currentStatus,
				// 	title: "Request ticket",
				// 	name: "request-ticket",
				// 	imageUrl: "/images/request-ticket.svg",
				// },
				// {
				
				// {
				// 	status: currentStatus,
				// 	title: "Services history",
				// 	name: "services-history",
				// 	imageUrl: "/images/services-history.svg",
				// },
				// {
				// 	status: currentStatus,
				// 	title: "Chatbox",
				// 	name: "chat-box",
				// 	imageUrl: "/images/chat-box.svg",
				// },
				// {
				// 	status: currentStatus,
				// 	title: "In place services request",
				// 	name: "in-place-services",
				// 	imageUrl: "/images/in-place-services.svg",
				// },
				// {
				// 	status: currentStatus,
				// 	title: "Coming soon",
				// 	name: "coming-soon",
				// 	imageUrl: "/images/coming-soon.svg",
				// },
			]);
		} catch (error) {
			alert(error.toString());
		}
	};

	const listItems = items.map((item) => (
		<li data-name={item.name} data-status={item.status}>
			<a
				href="javascript:void(0);"
				onClick={() => {
					return checkForInstallation(item.status, item.linkUrl);
				}}
			>
				<img src={item.imageUrl} alt={item.title} />
				<span>{t(item.title)}</span>
			</a>
		</li>
	));

	return (
		<>
			<ul className="list-items">{listItems}</ul>
			<Dialog
				header={localizer("Initial installation")}
				visible={visible}
				onHide={() => {
					if (!visible) return;
					setVisible(false);
				}}
			>
				<p>{localizer("Please complete initial installation")} </p>
			</Dialog>
		</>
	);
}
