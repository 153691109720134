import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller";
import { PrimeIcons } from "primereact/api";

export default function TopToolBar() {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();

	const items = [
		{
			title: localizer("Home page"),
			name: "home",
			imageUrl: "/images/home.svg",
			linkUrl: "/",
		},
		{
			title: localizer("Profile"),
			name: "profile",
			imageUrl: "/images/profile.svg",
			linkUrl: "/#/profile",
		},

		// {
		//   title: localizer("Config"),
		//   name: "config",
		//   imageUrl: "/images/config.svg",
		//   linkUrl: "/#/config",
		// },
	];

	const listItems = items.map((item) => (
		<li data-name={item.name}>
			<a href={item.linkUrl} title={item.title}>
				<img src={item.imageUrl} />
			</a>
		</li>
	));

	useEffect(() => {}, []);
 
	return (
		<>
			<div className="top-toolbar">
				<ul>
					{listItems} 
					<li>
						<a
							href="#"
							onClick={() => window.history.back()}
							title={localizer("Back")}
						>
							<i className={PrimeIcons.CHEVRON_LEFT} />
						</a>
					</li>
				</ul>
			</div>
		</>
	);
}
