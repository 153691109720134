import "../styles/online-help.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { Skeleton } from "primereact/skeleton";

export default function OnlineHelp() {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const toast = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [first, setFirst] = useState(1);
	const [rows, setRows] = useState(12);
	const [totalRecords, setTotalRecords] = useState(0);
	//
	const [onlineHelps, setOnlineHelps] = useState([]);

	const getData = async function () {
		try {
			setIsLoading(true);
			const onlineHelpsResult = await serviceController.getOnlineHelps(
				first,
				12
			);
			if (onlineHelpsResult.isSuccess === false)
				throw onlineHelpsResult.message;
			setOnlineHelps(onlineHelpsResult.value);
			setTotalRecords(onlineHelpsResult.total);
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
	};

	const addOnlineHelpLog = async function (crmOnlineHelpId) {
		try {
			let newOnlineHelps = onlineHelps.map((item) => item);
			newOnlineHelps.find(
				(item) => item.onlineHelpId === crmOnlineHelpId
			).isSeen = true;
			await serviceController.addOnlineHelpLog(crmOnlineHelpId);
			setOnlineHelps(newOnlineHelps);
		} catch (error) {
			alert(error.toString());
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<article className="online-help w-12 m-auto">
			<h2>{localizer("View help videos")}</h2>
			<p>مشتری گرامی :</p>

			<p>ضمن تشکر بابت انتخاب نرم‌افزار حسابداری محک</p>
			<p>
				خواهشمندیم جهت شروع کار با نرم‌افزار حسابداری محک و یا افزونه خریداری
				شده، به منظور استفاده درست از نرم‌افزار ، ویدیوهای آموزشی تهیه شده ذیل
				را حتما مشاهده فرمایید.
			</p>
			{isLoading === true && (
				<>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-4"></Skeleton>
						<Skeleton height="15rem" className="w-4"></Skeleton>
						<Skeleton height="15rem" className="w-4"></Skeleton>
					</div>
					<div className="flex gap-4 mb-4">
						<Skeleton height="15rem" className="w-4"></Skeleton>
						<Skeleton height="15rem" className="w-4"></Skeleton>
						<Skeleton height="15rem" className="w-4"></Skeleton>
					</div>
				</>
			)}
			<ul>
				{onlineHelps.map((item) => (
					<li data-isseen={item.isSeen}>
						<a
							target="_blank"
							rel="noreferrer"
							href={item.linkUrl}
							onClick={() => addOnlineHelpLog(item.onlineHelpId)}
						>
							<img src={item.iconUrl} alt={item.title} />
							<span>{item.title}</span>
							<p>{item.description}</p>
							<strong>مشاهده شد</strong>
						</a>
					</li>
				))}
			</ul>
			<Paginator
				first={first}
				rows={rows}
				totalRecords={totalRecords}
				onPageChange={onPageChange}
			/>
		</article>
	);
}
