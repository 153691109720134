import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { subscribe } from "../utility";
import ServiceController from "../services/service-controller";
import { PanelMenu } from "primereact/panelmenu";

export default function SideInformation() {
	const [visible, setvisible] = useState(false);
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();

	const [customerPackageId, setCustomerPackageId] = useState("");
	const [serial, setSerial] = useState("");
	const [startContractDate, setStartContractDate] = useState(null);
	const [supportDate, setSupportDate] = useState(null);
	const [stateId, setStateId] = useState("");
	const [cityId, setCityId] = useState("");
	const [cityName, setCityName] = useState("");
	const [stateName, setStateName] = useState("");
	const [saleAgentName, setSaleAgentName] = useState("");
	const [serviceAgentName, setServiceAgentName] = useState("");
	const [packageName, setPackageName] = useState("");
	const [customerName, setCustomerName] = useState("");
	const [remainDays, setRemainDays] = useState("");
	const [extensions, setExtensions] = useState([]);

	window.showMenu = function () {
		setvisible(true);
	};

	useEffect(() => {
		getData();
	}, []);

	const getData = async function () {
		try {
			var packageResult = await serviceController.getPackage();
			if (packageResult.isSuccess === false) throw packageResult.message;

			const objPackage = packageResult.value;

			setCustomerPackageId(objPackage.customerPackageId);
			setSerial(objPackage.serial);
			setStartContractDate(
				objPackage.startContractDate
					? new Date(objPackage.startContractDate).toLocaleDateString(
							"fa-IR-u-nu-latn"
					  )
					: ""
			);
			setSupportDate(
				objPackage.supportDate
					? new Date(objPackage.supportDate).toLocaleDateString(
							"fa-IR-u-nu-latn"
					  )
					: ""
			);
			setStateId(objPackage.stateId);
			setCityId(objPackage.cityId);
			setCityName(objPackage.cityName);
			setStateName(objPackage.stateName);
			setSaleAgentName(objPackage.saleAgentName);
			setServiceAgentName(objPackage.serviceAgentName);
			setPackageName(objPackage.packageName);
			setCustomerName(objPackage.customerName);
			//calc remain days
			setRemainDays("");
			if (objPackage.supportDate) {
				let days = Math.ceil(
					(new Date(objPackage.supportDate) - new Date()) /
						(1000 * 60 * 60 * 24)
				);
				setRemainDays(days.toString());
			}
			//
			if (!objPackage.extensions) objPackage.extensions = [];
			setExtensions(objPackage.extensions);
		} catch (error) {
			alert(error.toString());
		}
	};

	subscribe("setPackageEvent", (data) => {});

	const items = [
		{
			title: localizer("Package type"),
			value: packageName,
			linkTitle: localizer("Other your packages"),
			linkUrl: "#",
		},
		//{ title: localizer("Updated product price"), value: "122,610,1000 ریال", linkTitle: "", linkUrl: "" },
		{
			title: localizer("Contract start"),
			value: startContractDate,
			linkTitle: "",
			linkUrl: "",
		},
		{
			title: localizer("Sales agent"),
			value: saleAgentName,
			linkTitle: "",
			linkUrl: "",
		},
		{
			title: localizer("Services agent"),
			value: serviceAgentName,
			linkTitle: "",
			linkUrl: "",
		},
		//{ title: localizer("Software version"), value: "8.520", linkTitle: "مزایای آپدیت به نسخه 9.560", linkUrl: "#" },
	];

	const listInformation = items.map((item) => (
		<li data-name={item.name}>
			<label>{item.title}</label>
			<h2>{item.value}</h2>
			{item.linkUrl ? <a href="#">{item.linkTitle}</a> : ""}
		</li>
	));
	return (
		<>
			<div className="side-info">
				<div className="cart">
					<label>{localizer("Package number")}</label>
					<strong>{serial}</strong>
					<label>{localizer("Package owner")}</label>
					<strong>{customerName}</strong>
					<div className="finish">
						<label>{localizer("Support finish date")}</label>
						<strong>{supportDate}</strong>
						{remainDays > 0 ? (
							<span className="active">
								{remainDays} {localizer("Day")}
							</span>
						) : (
							<span className="expired">
								{remainDays} {localizer("Day")}
							</span>
						)}
					</div>
				</div>
				<ul>{listInformation}</ul>
			</div>
			<PanelMenu
				model={[
					{
						label: localizer("extensions") + "(" + extensions.length + ")",
						icon: "",
						items: extensions.map((item) => {
							return { label: item.quantity + " " + item.name, icon: "" };
						}),
					},
				]}
				className="w-full md:w-20rem"
			/>
		</>
	);
}
