import "../styles/troubleshooting.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceController from "../services/service-controller";
import UserController from "../services/user-controller";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { json } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

export default function Troubleshooting() {
	const { t } = useTranslation();
	const localizer = t;
	const serviceController = new ServiceController();
	const userController = new UserController();
	const toast = useRef(null);
	const [total, setTotal] = useState(0);
	const [totalSeen, setTotalSeen] = useState(0);
	const [totalRemain, setTotalRemain] = useState(0);
	const [isRegisterPage, setIsRegisterPage] = useState(false);
	const [troubleshootings, setTroubleshootings] = useState([]);
	const [remainTroubleshootings, setRemainTroubleshootings] = useState([]);

	//> Commpon Properties
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	//
	const [customerPackage, setCustomerPackage] = useState({});
	//> Properties
	const [customerName, setCustomerName] = useState(null);
	const [mobile, setMobile] = useState(null);
	const [tel, setTel] = useState(null);
	const [requestDescription, setRequestDescription] = useState(null);
	const [requestDate,setRequestDate] = useState("");

	//> getData
	const getData = async function () {
		try {
			setIsLoading(true);
			const onlineHelpsSummaryResult =
				await serviceController.getOnlineHelpsSummary();
			if (onlineHelpsSummaryResult.isSuccess === false)
				throw onlineHelpsSummaryResult.message;
			setTotal(onlineHelpsSummaryResult.value.total);
			setTotalSeen(onlineHelpsSummaryResult.value.totalSeen);
			setTotalRemain(onlineHelpsSummaryResult.value.totalRemain);
			// get profile
			const profileResult = await userController.getProfile();
			if (profileResult.isSuccess === false)
				console.error(profileResult.message);
			else {
				setCustomerName(profileResult.value.fullName);
				setMobile(profileResult.value.mobile);
				setTel(profileResult.value.tel);
			}
			//
			const packageResult = await serviceController.getPackage();
			if (packageResult.isSuccess === false) alert(packageResult.message);
			else if (packageResult.value) {
				setCustomerPackage(packageResult.value);
			}
			//
			if (onlineHelpsSummaryResult.value.totalRemain <= 0) {
				await applyTroubleShootings();
			}
			//
		} catch (error) {
			alert(error.toString());
		}
		setIsLoading(false);
	};

	//> applyTroubleShootings
	const applyTroubleShootings = async function () {
		const packageResult = await serviceController.getPackage();
		if (packageResult.isSuccess === false) {
			alert(packageResult.message);
			return;
		}
		//
		const troubleshootingsResult =
			await serviceController.getTroubleshootings();
		if (troubleshootingsResult.isSuccess === false)
			console.error(troubleshootingsResult.message);
		else setTroubleshootings(troubleshootingsResult.value);
		//>
		let _remainTroubleshootings = [];
		let days = [
			"اول",
			"دوم",
			"سوم",
			"چهارم",
			"پنجم",
			"ششم",
			"هفتم",
			"هشتم",
			"نهم",
			"دهم",
		];
		for (
			let i = troubleshootingsResult.value.length;
			i < packageResult.value.totalSessions;
			i++
		) {
			let title = "جلسه " + (i + 1);
			if (i < days.length) title = "جلسه " + days[i];
			_remainTroubleshootings.push({
				sessionName: title,
				isDisabled: true,
			});
		}
		if (_remainTroubleshootings.length > 0)
		{
			_remainTroubleshootings[0].isDisabled = false;
			if(troubleshootingsResult.value.length > 0 && troubleshootingsResult.value[troubleshootingsResult.value.length - 1].isComlepeted !== true)
				_remainTroubleshootings[0].isDisabled = true;
		}
		setRemainTroubleshootings(_remainTroubleshootings);
	};

	//> register
	const register = async function () {
		try {
			setIsSaving(true);
			const createResult = await serviceController.createTroubleshooting(
				customerName,
				mobile,
				tel,
				requestDescription,
				requestDate
			);
			if (createResult.isSuccess === false) throw createResult.message;

			setRequestDescription("");
			toast.current.show({
				severity: "success",
				summary: localizer("Register troubleshooting"),
				detail: localizer("Your request troubleshooting registered"),
			});
			setIsRegisterPage(false);
			getData();
		} catch (error) {
			alert(error.toString());
		}
		setIsSaving(false);
	};

	//> cancel
	const cancel = function(){
		setIsRegisterPage(false);
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<article className="troubleshooting w-12 m-auto">
			<h2>{localizer("Troubleshooting request")}</h2>
			{isLoading && (
				<>
					<Skeleton className="mb-2"></Skeleton>
					<Skeleton width="10rem" className="mb-2"></Skeleton>
					<Skeleton width="5rem" className="mb-2"></Skeleton>
					<Skeleton height="2rem" className="mb-2"></Skeleton>
					<Skeleton width="10rem" height="4rem"></Skeleton>
				</>
			)}
			{totalRemain > 0 && (
				<div className="waiting-for-videos">
					<img src="/images/not-seen-videos.jpg" alt="not-seen-videos" />
					<p>
						برای ثبت درخواست رفع اشکال باید ابتدا همه ویدئوهای بخش راهنمای
						آنلاین را ببنید ، شما از مجموع
						<b> {total} </b>
						ویدئوی آموزشی مربوط به این بسته
						<b> {totalSeen} </b>
						ویدئو را مشاهده کرده اید
					</p>
					<a href="/#/online-help">
						برای مشاهده ویدئوهای باقی مانده بر روی این قسمت کلیک کنید
					</a>
				</div>
			)}

			{isLoading === false && totalRemain <= 0 && (
				<>
					{isRegisterPage === false && (
						<ul className="sessions">
							{troubleshootings.map((item) => (
								<li className="old">
									<h2> {item.title} </h2>
									<div>
										<div>
											<label>تاریخ جلسه</label>
											<span>
												{new Date(item.requestDate).toLocaleString("fa-IR", {
													dateStyle: "short",
												})}
											</span>
										</div>
										<div>
											<label>شرح درخواست</label>
											<span>{item.requestDescription}</span>
										</div>
										<div>
											<label>وضعیت درخواست</label>
											<span>
												{item.isComlepeted ? "انجام شده" : "در دست پیگیری"}
											</span>
										</div>
									</div>
								</li>
							))}
							{remainTroubleshootings.length <= 0 && (
								<li className="finished">تمام جلسات انجام شده است</li>
							)}
							{remainTroubleshootings.map((item) => (
								<li className="new">
									<button
										disabled={item.isDisabled}
										onClick={() => setIsRegisterPage(true)}
									>
										درخواست {item.sessionName}
									</button>
								</li>
							))}
						</ul>
					)}
					{isRegisterPage === true && (
						<div className="grid w-10 m-auto">
							<FloatLabel className="col-4">
								<InputText
									id="agentName"
									className="w-full"
									value={customerPackage.serviceAgentName}
									readOnly
								/>
								<label htmlFor="agentName">نام نماینده خدمات </label>
							</FloatLabel>
							<FloatLabel className="col-4">
								<InputText
									id="agentTel"
									className="w-full"
									value={customerPackage.serviceAgentTel}
									readOnly
								/>
								<label htmlFor="agentTel">شماره تماس نماینده خدمات </label>
							</FloatLabel>
							<FloatLabel className="col-4">
								<InputText
									id="serial"
									className="w-full ltr"
									value={customerPackage.serial}
									readOnly
								/>
								<label htmlFor="serial">سریال بسته مشتری </label>
							</FloatLabel>

							<FloatLabel className="col-4">
								<InputText
									id="customerName"
									className="w-full"
									value={customerName}
									onChange={(e) => setCustomerName(e.target.value)}
								/>
								<label htmlFor="customerName">
									{localizer("Request recipient name")}
								</label>
							</FloatLabel>
							<FloatLabel className="col-4">
								<InputText
									id="mobile"
									className="w-full"
									value={mobile}
									onChange={(e) => setMobile(e.target.value)}
								/>
								<label htmlFor="mobile">
									{localizer("Request recipient mobile")}
								</label>
							</FloatLabel>
							<FloatLabel className="col-4">
								<InputText
									id="tel"
									className="w-full ltr"
									value={tel}
									onChange={(e) => setTel(e.target.value)}
								/>
								<label htmlFor="tel">
									{localizer("Customer service phone")}
								</label>
							</FloatLabel>

							<FloatLabel className="col-4">
							<DatePicker
								value={requestDate}
								onChange={(value) => setRequestDate(value)}
								calendar={persian}
								locale={persian_fa}
								id="requestDate"
								className="w-full"
								mapDays={({ date }) => {
									if (date.weekDay.index === 6)
										return {
											disabled: true,
											style: { color: "#f00" },
											//onClick: () => alert("شما نمیوانید روز تعطیل را انتخاب کنید")
										};
								}}
							/>
							<label htmlFor="requestDate">تاریخ درخواستی رفع اشکال</label>
						</FloatLabel>

							<FloatLabel className="col-8">
								<InputTextarea
									id="requestDescription"
									className="w-full ltr"
									value={requestDescription}
									onChange={(e) => setRequestDescription(e.target.value)}
								/>
								<label htmlFor="requestDescription">
									{localizer("Request description")}
								</label>
							</FloatLabel>
							<Toast ref={toast} />
							<Button
								label={localizer("Register request")}
								onClick={() => register()}
								severity="success"
								loading={isSaving}
							/>
							<Button
								label={localizer("Cancel")}
								onClick={() => cancel()}
								severity="danger"
								loading={isSaving}
							/>
						</div>
					)}
				</>
			)}
		</article>
	);
}
